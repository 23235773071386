<template>
  <div>
    <FullScreenDialog
      v-model="orderDialog"
      title="Ordini"
      :routeFather="pathToGoBack"
    >
      <template v-slot:header-actions>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <SimpleList
            identifier-key="key"
            :items="rightMenu"
            @item-click="handleItemClick"
          ></SimpleList>
        </v-menu>
      </template>
      <template v-slot:toolbar-extension>
        <v-tabs v-model="tab" align-with-title>
          <v-tabs-slider color="primary"></v-tabs-slider>
          <v-tab v-for="tab in orderTabs" :key="tab.key">{{ tab.title }}</v-tab>
        </v-tabs>
      </template>
      <template v-slot:content>
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="tabItem in orderTabs" :key="tabItem.key">
            <component
              :supplier-from-voice="supplierFromVoice"
              :is="tabItem.component"
            ></component>
          </v-tab-item>
        </v-tabs-items>
      </template>
      <template v-slot:footer-actions>
        <v-spacer></v-spacer>
        <v-btn
          text
          class="border-default-red"
          color="error"
          @click="close(pathToGoBack)"
        >
          ANNULLA</v-btn
        >
        <v-btn
          text
          class="border-default"
          :disabled="!formValid"
          @click="openPreview"
          >Procedi</v-btn
        >
      </template>
    </FullScreenDialog>
    <ErrorDialog
      dialog-width="360"
      dialog-height="360"
      persistent
      v-model="showErrorDialog"
      title="Errore"
    >
      {{ errorMessage }}
    </ErrorDialog>

    <OrderCreateDialog
      v-if="openOrderCreateDialog"
      :items-selected="insertionOrderItemObject"
      :current-order="currentOrder"
      :open-dialog="openOrderCreateDialog"
      @close="openOrderCreateDialog = false"
      @saved-order="saveOrder"
    ></OrderCreateDialog>
  </div>
</template>

<script>
import ErrorDialog from "@/components/common/StandardDialog";

import FullScreenDialog from "@/components/common/FullScreenDialog.vue";
import { SimpleList } from "likablehair-ui-components";
import OrderNewForm from "@/components/areas/warehouse/orders/new/OrderNewFormTab.vue";

import OrderCreateDialog from "./new/OrderCreateDialog.vue";
import OrderFromUnderStockNewForm from "@/components/areas/warehouse/orders/new/OrderUnderStockNewForTab.vue";
import OrderFromGenericElement from "@/components/areas/warehouse/orders/new/OrderFromGenericElement.vue";
export default {
  name: "OrderHome",
  components: {
    FullScreenDialog,
    SimpleList,
    ErrorDialog,
    OrderNewForm,
    OrderFromUnderStockNewForm,
    OrderCreateDialog,
    OrderFromGenericElement,
  },
  data: function () {
    return {
      tab: null,
      tabComponent: undefined,
      orderDialog: this.openDialog,
      openOrderCreateDialog: false,
      orderTabs: this.tabs,
      showErrorDialog: false,
      errorMessage: "",
      rightMenu: [
        {
          title: "Lista Ordini",
          icon: "mdi-format-list-text",
          routeName: "OrderList",
        },
      ],
      originalUrl: window.location.pathname,
    };
  },
  mounted() {
    this.changeTabBasedOnProp(this.tabName);
  },
  props: {
    openDialog: {
      type: Boolean,
      default: false,
    },
    pathToGoBack: {
      type: String,
      default: "/warehouse",
    },
    tabName: {
      type: String,
      default: "new/insertion-tab",
    },
    supplierFromVoice: {
      type: String,
      default: undefined
    },
    tabs: {
      type: Array,
      default: function () {
        return [
          {
            key: 0,
            title: "Ordine per Fornitore",
            icon: "mdi-book",
            name: "new/insertion-tab",
            component: "OrderNewForm",
          },
          {
            key: 1,
            title: "Ordine per Fornitore da sottoscorta",
            icon: "mdi-book",
            name: "new/under-stock-insertion-tab",
            component: "OrderFromUnderStockNewForm",
          },
          {
            key: 2,
            title: "Ordine merce generica",
            icon: "mdi-book",
            name: "new/items-not-present-in-warehouse-tab",
            component: "OrderFromGenericElement",
          },
        ];
      },
    },
  },
  methods: {
    changeTabBasedOnProp(tabName) {
      for (let i = 0; i < this.orderTabs.length; i++) {
        if (this.orderTabs[i].name == tabName) {
          this.tab = i;
          break;
        }
      }
    },
    close(routeToGoBack) {
      this.orderDialog = false;
      this.$router.push({ path: routeToGoBack });
    },
    handleItemClick(item) {
      this.goTo(item.routeName);
    },
    goTo(routeName) {
      this.$router.push({ name: routeName }).catch(() => {});
    },

    openPreview() {
      this.openOrderCreateDialog = true;
    },

    saveOrder() {
      this.$store.dispatch("warehouse/orders/resetFields");
      this.goTo("OrderList");
    },
  },
  watch: {
    openDialog(newVal) {
      this.orderDialog = newVal;
    },
  },
  computed: {
    insertionOrderItemObject() {
      return this.$store.state.warehouse.orders.data.order.currentItemsSelected;
    },
    currentOrder() {
      return this.$store.state.warehouse.orders.data.order;
    },
    formValid() {
      return this.$store.state.warehouse.orders.data.valid;
    },
    isCreateRoute() {
      if (this.tabComponent === "OrderNewForm") return true;
      else return false;
    },
  },
  watch: {
    tab(newVal) {
      this.tabComponent = this.orderTabs[newVal].component;
      try {
        window.history.pushState(
          "Tab",
          "Tab",
          this.originalUrl + "?tabName=" + this.orderTabs[newVal].name
        );
      } catch (err) {
        console.log("try setting the tab name but something goes wrong");
      }
    },
  },
};
</script>

<style scoped>
.border-default {
  padding: 5px;
  border-radius: 25px;
  border: 1px solid #dcd8d8;
}
.border-default-red {
  padding: 5px;
  border-radius: 25px;
  border: 1px solid #ee7d7d;
}

.v-tabs:not(.v-tabs--vertical) .v-tab {
    white-space: nowrap;
}
</style>