module.exports = {
  ItemNotPresent: {
    key: "ItemNotPresent_Error",
    desc:
      "Il prodotto non è presente a magazzino, impossibile effettuare scarico",
  },
  ItemNotPresent_Add: {
    key: "ItemNotPresent_Add",
    desc:
      "Il prodotto non è presente a magazzino, impossibile effettuare carico. Vuoi inserirlo o associarlo a prodotto già esistente?",
  },
  ItemAlreadySelected: {
    key: "ItemAlreadySelected",
    desc:
      "Prodotto già selezionato",
  },
  ItemNotPresent_Add_OrderCreated: {
    key: "ItemNotPresent_Add_OrderCreated",
    desc:
      "Il prodotto non è presente a magazzino, impossibile aggiungerlo.",
  },
  Correct_Qty: {
    key: "Correct_Qty",
    desc: "Inserire quantità corretta",
  },
  Qty_Not_Enough:{
    key: "Qty_Not_Enough",
    desc: "Quantità non sufficiente a magazzino",
  },
  Unload_Complete: {
    key: "Unload_Complete",
    desc: "Effettuato lo scarico dei prodotti selezionati",
  },
  Unload_QtyEqualZero: {
    key: "Unload_QtyEqualZero",
    desc:
      "Scannerizzato un prodotto con quantità a magazzino uguale a 0. Impossibile effettuare scarico.",
  },
  Upload_Complete: {
    key: "Upload_Complete",
    desc: "Caricati a magazzino tutti i prodotti selezionati",
  },
  Supplier_NotSelected: {
    key: "Supplier_NotSelected",
    desc: "Fornitore non selezionato, campo obbligatorio",
  },
  Desc_NotPresent: {
    key: "Desc_NotPresent",
    desc: "Descrizione prodotto non inserita, campo obbligatorio",
  },
  Code_NotPresent: {
    key: "Code_NotPresent",
    desc: "Barcode non inserito, campo obbligatorio",
  },
  Code_AlreadyPresent: {
    key: "Code_AlreadyPresent",
    desc: "Barcode già utilizzato",
  },
  Order_FromCreate_ToWaiting: {
    key: 'Order_FromCreate_ToWaiting',
    desc: "Ordine creato, sicuro di volerlo mettere in Attesa ?"
  },
  Order_FromWaiting_ToFulFilled: {
    key: 'Order_FromWaiting_ToFulFilled',
    desc:"Ordine in Attesa, vuoi modificarlo prima di Evadere ?"
  },
  Order_ChangeStatus_Error: {
    key: 'Order_ChangeStatus_Error',
    desc:"Impossibile effetuare azione, passagio di stato dell' ordine non corretto"
  },
  Order_Waiting :{
    key:'Order_Waiting',
    desc:"Ordine In Attesa"

  }
};
