<template>
  <StandardDialog
    dialog-width="90vw"
    dialog-height="calc(90vw-10px)"
    persistent
    v-model="localOpenDialog"
    :title="title"
  >
    <v-form class="mb-10" v-model="formValid">
      <v-subheader class="font-italic font-weight-light py-1">
        Dati Generali:
      </v-subheader>
      <v-row no-gutters>
        <v-col cols="4">
          <v-text-field
            v-model="code"
            filled
            disabled
            label="Codice Ordine"
            prepend-icon="mdi-code-tags"
            hide-details="auto"
            rounded
          ></v-text-field>
        </v-col>
        <v-col cols="5">
          <v-text-field
            v-model="descOrder"
            :placeholder="descPlaceHolder"
            filled
            class="ml-2"
            :rules="[presenceRule]"
            label="Descrizione Ordine"
            prepend-icon="mdi-clipboard-text"
            hide-details="auto"
            rounded
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <DateTimePicker
            v-model="expectedDeliveryDate"
            dense
            filled
            class="ml-3"
            label="Data di consegna prevista"
            :show-time="false"
            date-picker-width="300px"
            :min="(() => {
              let today = new Date()
              return new Date(today.setDate(today.getDate() + 1))
            })()"
          ></DateTimePicker>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-textarea
            v-model="reason"
            label="Note"
            filled
            hide-details="auto"
            prepend-icon="mdi-comment"
            rows="2"
            row-height="15"
            :rules="[max255CharRule]"
            rounded
          ></v-textarea>
        </v-col>
      </v-row>
    </v-form>

    <div
      class="mt-5 border-default"
      style="
        overflow-x: hidden;
      "
      ref="dataTableWrapper"
    >
      <ItemDataTable
        :show-select="false"
        class="mt-10"
        :loading="loading"
        :items="localItemsSelected"
        :disable-pagination="true"
        :headers="!!supplierSelected ? headers : headersForOrderGeneric"
        :show-actions="false"
      >
        <template v-slot:custom-pricePurchase="{ item }">
          <v-chip color="primary">
            € {{ item["pricePurchase"] ? item["pricePurchase"] : 0 }}</v-chip
          >
        </template>
        <template v-slot:custom-discountedPrice="{ item }">
          <v-chip color="primary">
            €
            {{
              item["pricePurchase"] && item["discount"]
                ? Number(
                    Number(item["pricePurchase"]) *
                      (1 - Number(item["discount"]) / 100)
                  ).toFixed(2)
                : Number(item["pricePurchase"]).toFixed(2)
            }}</v-chip
          >
        </template>
        <template v-slot:custom-discount="{ item }">
          <v-chip color="white">
            % {{ item["discount"] ? item["discount"] : 0 }}</v-chip
          >
        </template>
        <template v-slot:custom-priceTot="{ item }">
          <v-chip color="primary">
            €
            {{
              item["pricePurchase"] && item["discount"] && item["qtyTidying"]
                ? Number(
                    Number(item["pricePurchase"]) *
                      (1 - Number(item["discount"]) / 100) *
                      item["qtyTidying"]
                  ).toFixed(2)
                : Number(item["pricePurchase"] * item["qtyTidying"]).toFixed(2)
            }}</v-chip
          >
        </template>
        <template v-slot:custom-qtyTidying="{ item }">
          <v-chip color="white">
            {{ item["qtyTidying"] ? item["qtyTidying"] : 0 }} pz</v-chip
          >
        </template>
        <template v-slot:custom-qtyMinStock="{ item }">
          <v-chip color="orange">
            {{ item["qtyMinStock"] ? item["qtyMinStock"] : 0 }}</v-chip
          >
        </template>

        <template v-slot:custom-qtyInStock="{ item }">
          <v-chip v-if="item['qtyInStock'] > 0" color="success">
            {{ item["qtyInStock"] }}</v-chip
          >
          <v-chip v-else color="error">
            {{ item["qtyInStock"] ? item["qtyInStock"] : 0 }}</v-chip
          >
        </template>
      </ItemDataTable>
    </div>

    <v-row>
      <v-spacer></v-spacer>
      <OrderSummary :selected-items="localItemsSelected"></OrderSummary>
    </v-row>
    <ErrorDialog
      dialog-width="360"
      dialog-height="360"
      persistent
      v-model="showErrorDialog"
      title="Errore"
    >
      {{ errorMessage }}
    </ErrorDialog>

    <template v-slot:footer-actions>
      <v-btn class="border-default-red" text color="error" @click="close"
        >Annulla</v-btn
      >
      <v-btn
        class="border-default"
        text
        :disabled="!formValid"
        @click="saveOrder"
        >Salva</v-btn
      >
    </template>
  </StandardDialog>
</template>

<script>
import Vue from "vue";
import orderService from "@/services/warehouse/orders.service.js";
import ItemDataTable from "@/components/common/TypeDataTable.vue";
import StandardDialog from "@/components/common/StandardDialog";
import ErrorDialog from "@/components/common/StandardDialog";
import currentUser from "@/services/currentUser.service";
import DateTimePicker from '@/components/common/DateTimePicker.vue'
import BorderedDiv from "@/components/common/BorderedDivComponent.vue";
import OrderSummary from "@/components/areas/warehouse/orders/components/OrderSummary.vue";
export default {
  name: "OrderCreateDialog",
  components: {
    StandardDialog,
    ItemDataTable,
    ErrorDialog,
    BorderedDiv,
    OrderSummary,
    DateTimePicker
  },

  props: {
    openDialog: {
      type: Boolean,
      default: true,
    },
    itemsSelected: {
      type: Array,
      default: function () {
        [];
      },
    },
    bus: {
      type: Object,
      default: function () {
        return new Vue();
      },
    },
    currentOrder: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data: function () {
    return {
      showErrorDialog: false,
      errorMessage: "",
      selectedItems: [],
      formValid: false,
      code: "",
      reason: "",
      priceTotSelling: 0.0,
      priceTotPurchase: 0.0,
      priceTotNetPurchase: 0.0,
      codePlaceHolder: "ES...OR01",
      descOrder: "",
      descPlaceHolder: "ES...Prodotti Salone, Prodotti Bar",
      discount: undefined,
      amount: undefined,
      loading: false,
      localOpenDialog: false,
      localItemsSelected: undefined,
      supplierSelected: undefined,
      expectedDeliveryDate: undefined,

      title: "Creazione Ordine per il fornitore",
      headersForOrderGeneric: [
        {
          text: "Descrizione",
          value: "description",
        },
        {
          text: "Prezzo listino d'acquisto",
          value: "pricePurchase",
          type: "custom",
        },

        {
          text: "% di Sconto",
          value: "discount",
          type: "custom",
          width: "150px"
        },
        {
          text: "Prezzo unitario scontato",
          value: "discountedPrice",
          type: "custom",
        },
        {
          text: "N° pezzi da ordinare",
          value: "qtyTidying",
          type: "custom",
          width: "150px"
        },
        {
          text: "Totale Scontato",
          value: "priceTot",
          type: "custom",
        },
      ],
      headers: [
        {
          text: "Codice Interno",
          value: "internalCode",
        },
        {
          text: "Barcode",
          value: "code",
        },
        {
          text: "Descrizione",
          value: "description",
        },
        {
          text: "Quantità in Giacenza",
          value: "qtyInStock",
          type: "custom",
        },
        {
          text: "Scorta minima",
          value: "qtyMinStock",
          type: "custom",
        },
        {
          text: "Prezzo listino d'acquisto",
          value: "pricePurchase",
          type: "custom",
        },

        {
          text: "% di Sconto",
          value: "discount",
          type: "custom",
          width: "150px"
        },
        {
          text: "Prezzo unitario scontato",
          value: "discountedPrice",
          type: "custom",
        },
        {
          text: "N° pezzi da ordinare",
          value: "qtyTidying",
          type: "custom",
          width: "150px"
        },
        {
          text: "Totale Scontato",
          value: "priceTot",
          type: "custom",
        },
      ],
      presenceRule: (v) => {
        return !!v || "Il campo è obbligatorio";
      },
      max8CharRule: (v) => {
        if (v) {
          return v.length <= 8 || "Al massimo 8 caratteri";
        } else {
          return true;
        }
      },
      max255CharRule: (v) => {
        if (v) {
          return v.length <= 255 || "Al massimo 255 caratteri";
        } else {
          return true;
        }
      },
    };
  },
  mounted() {
    this.setUser();
    this.setOrder();
    this.localOpenDialog = this.openDialog;
  },
  methods: {
    setOrder() {
      let currentOrder = this.currentOrder //this.$store.state.warehouse.orders.data.order;

      this.title = !!currentOrder.businessName
        ? "Creazione Ordine: " +
          currentOrder.code +
          " per " +
          currentOrder.businessName
        : "Creazione Ordine: " + currentOrder.code;
      this.code = currentOrder.code;
      this.descOrder = currentOrder.description;
      this.reason = currentOrder.reason;

      this.priceTotNetPurchase = parseFloat(
        currentOrder.priceTotNetPurchase
      ).toFixed(2);
      this.priceTotPurchase = parseFloat(currentOrder.priceTotPurchase).toFixed(
        2
      );
      this.priceTotSelling = parseFloat(currentOrder.priceTotSelling).toFixed(
        2
      );
      this.localItemsSelected = currentOrder.currentItemsSelected;
      this.localItemsSelected.extraDiscount = currentOrder.discount;
      this.discount = Number(currentOrder.discount);
      this.amount = Number(currentOrder.amount);
      this.expectedDeliveryDate = currentOrder.expectedDeliveryDate
      this.supplierSelected = currentOrder.businessName ? true : false;
    },
    setUser() {
      let userId = 0;
      currentUser.getUser().then((user) => {
        userId = user.id;
        this.updateField("userId", userId);
      });
    },
    updateField(nameField, value) {
      this.$store.dispatch("warehouse/orders/updateField", {
        field: nameField,
        value: value,
      });
    },
    close() {
      this.$emit("close");
    },
    saveOrder() {
      let orderToCreate = this.insertionOrderObject;
      orderService
        .createOrder(orderToCreate)
        .then((createdOrder) => {
          if (!!orderToCreate.businessName) {
            this.localItemsSelected.forEach((item) => {
              orderService
                .addItem(
                  createdOrder.id,
                  item.id,
                  item.qtyTidying,
                  item.pricePurchase,
                  item.priceNetPurchase,
                  item.discount,
                  item.code
                )
                .then(() => {
                  this.$emit("saved-order");
                })
                .catch((err) => {
                  this.errorMessage = err;
                  this.showErrorDialog = true;
                });
            });
          } else {
            let genericItems = [];
            this.localItemsSelected.map((genericItem) =>
              genericItems.push({
                price: Number(genericItem.pricePurchase),
                quantity: Number(genericItem.qtyTidying),
                description: genericItem.description,
              })
            );
            orderService
              .addGenericItems(createdOrder.id, genericItems)
              .then(() => {
                this.$emit("saved-order");
              })
              .catch((err) => {
                this.errorMessage = err;
                this.showErrorDialog = true;
              });
          }
        })
        .catch((err) => {
          this.errorMessage = err;
          this.showErrorDialog = true;
        });
    },
  },
  computed: {
    insertionOrderObject() {
      let order = this.$store.state.warehouse.orders.data.order;
      return order;
    },
  },
  watch: {
    descOrder(newVal) {
      this.updateField("description", newVal);
    },
    reason(newVal) {
      this.updateField("reason", newVal);
    },
  },
};
</script>

<style>
.border-default {
  padding: 5px;
  border-radius: 25px;
  border: 1px solid #dcd8d8;
}
.border-default-red {
  padding: 5px;
  border-radius: 25px;
  border: 1px solid #ee7d7d;
}
</style>